<template>
  <v-col class="ml-1 pa-6 pb-12">
    <h2 class="font-weight-bold headline hidden-sm-and-down">
      집행된 광고의 결과를 확인해보세요.
    </h2>
    <v-row class="mx-0">
      <v-col cols="12" md="4 pr-4">
        <v-col class="line py-2">
          <div class="body-1 font-weight-bold mt-6 mb-2">총 이용 회수</div>
          <span class="display-2 font-weight-light py-4">{{
            contracts.length
          }}</span>
        </v-col>
      </v-col>
      <v-col cols="12" md="4 pr-4">
        <v-col class="line py-2">
          <div class="body-1 font-weight-bold mt-6 mb-2">
            전체 가맹점 대비 만족지수
          </div>
          <span class="display-2 font-weight-light py-4"
            >{{ companyScore
            }}<span class="headline font-weight-light py-4"
              >/{{ totalScore }}</span
            ></span
          >
        </v-col>
      </v-col>
    </v-row>
    <v-col class="mx-0">
      <div class="body-1 font-weight-bold mt-6 mb-2">만족지수</div>
      <DashboardChart
        :width="660"
        :height="280"
        :datas="dashboardChartData"
      ></DashboardChart>
      <div class="body-1 font-weight-bold mb-2 mt-8">한 줄 후기</div>
      <div class="body-1 mb-2">
        한 줄 후기는 회원이 SNS에 등록하는 리뷰와는 별개로 외부에 공개되는
        자료가 아니며, 가맹점 서비스에 대한 피드백을 위해 솔직하게 작성되는
        후기입니다.
      </div>
      <v-data-table
        :headers="[
          {
            text: '등록된 후기',
            align: 'left',
            sortable: false,
            value: 'answer',
          },
        ]"
        :items="comments"
        :items-per-page="5"
        mobile-breakpoint="0"
        style="backgroud-color: transparent"
      >
        <!--        <template v-slot:header="props">-->
        <!--          <tr>-->
        <!--            <th class="text-left pa-0">-->
        <!--              <span class="font-weight-bold body-1 shades&#45;&#45;text text&#45;&#45;black">{{ props.headers[0].text }}</span>-->
        <!--            </th>-->
        <!--          </tr>-->
        <!--        </template>-->
        <template v-slot:item="props">
          <tr>
            <td class="pa-0">{{ props.item.answer }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-col>
</template>

<script>
import companyMixin from "@/components/shared/companyMixin";

import DashboardChart from "@/components/company/report/DashboardChart";

export default {
  name: "Comment",
  mixins: [companyMixin],
  props: ["contracts"],
  components: { DashboardChart },
  data() {
    return {
      comments: [],
      dashboardChartData: [],
    };
  },
  computed: {
    totalScore() {
      if (this.dashboardChartData.length > 0) {
        return this._.mean(
          this.dashboardChartData.map((data) => data.avg)
        ).toFixed(2);
      } else {
        return 0;
      }
    },
    companyScore() {
      if (this.dashboardChartData.length > 0) {
        let avg = this._.mean(
          this.dashboardChartData.map(
            (data) => (data.myAnswerInfo || {}).avg || 0
          )
        ).toFixed(2);
        if (isNaN(avg)) {
          return 0;
        } else {
          return avg;
        }
      } else {
        return 0;
      }
    },
  },
  methods: {
    getInitData(company) {
      if (company.question) {
        Promise.all(
          (company.question || []).map(async (question) => {
            let questionData = await this.axios.get("/question?id=" + question);
            if (questionData.data) {
              questionData = questionData.data.questions[0];
            }
            if (questionData.questionType === "I") {
              let answer = await this.axios.get(
                "/question/answer?questionId=" +
                  question +
                  "&company=" +
                  company.id
              );
              const questionAnswer_type_i = answer.data.filter(
                (elem) => !!elem.answer
              );
              questionData.answer = questionAnswer_type_i;
              this.comments = questionData.answer;
              this.comments.sort((a, b) => {
                return (
                  new Date(b.usedDate).getTime() -
                  new Date(a.usedDate).getTime()
                );
              });
            } else {
              let myAnswerInfo = await this.axios.get(
                "/question/answer/info?questionId=" +
                  question +
                  "&company=" +
                  company.id
              );
              questionData.myAnswerInfo = myAnswerInfo.data;
              this.dashboardChartData.push(questionData);
            }
          })
        );
      }
    },
  },
  created() {
    this.getInitData(this.company);
  },
};
</script>

<style scoped lang="scss">
::v-deep .line {
  border-bottom: 1px solid #bd1328;
  border-bottom: 1px solid var(--rouge-color);
  width: 100%;
}
</style>
