var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"ml-1 pa-6 pb-12"},[_c('h2',{staticClass:"font-weight-bold headline hidden-sm-and-down"},[_vm._v(" 집행된 광고의 결과를 확인해보세요. ")]),_c('v-row',{staticClass:"mx-0"},[_c('v-col',{attrs:{"cols":"12","md":"4 pr-4"}},[_c('v-col',{staticClass:"line py-2"},[_c('div',{staticClass:"body-1 font-weight-bold mt-6 mb-2"},[_vm._v("총 이용 회수")]),_c('span',{staticClass:"display-2 font-weight-light py-4"},[_vm._v(_vm._s(_vm.contracts.length))])])],1),_c('v-col',{attrs:{"cols":"12","md":"4 pr-4"}},[_c('v-col',{staticClass:"line py-2"},[_c('div',{staticClass:"body-1 font-weight-bold mt-6 mb-2"},[_vm._v(" 전체 가맹점 대비 만족지수 ")]),_c('span',{staticClass:"display-2 font-weight-light py-4"},[_vm._v(_vm._s(_vm.companyScore)),_c('span',{staticClass:"headline font-weight-light py-4"},[_vm._v("/"+_vm._s(_vm.totalScore))])])])],1)],1),_c('v-col',{staticClass:"mx-0"},[_c('div',{staticClass:"body-1 font-weight-bold mt-6 mb-2"},[_vm._v("만족지수")]),_c('DashboardChart',{attrs:{"width":660,"height":280,"datas":_vm.dashboardChartData}}),_c('div',{staticClass:"body-1 font-weight-bold mb-2 mt-8"},[_vm._v("한 줄 후기")]),_c('div',{staticClass:"body-1 mb-2"},[_vm._v(" 한 줄 후기는 회원이 SNS에 등록하는 리뷰와는 별개로 외부에 공개되는 자료가 아니며, 가맹점 서비스에 대한 피드백을 위해 솔직하게 작성되는 후기입니다. ")]),_c('v-data-table',{staticStyle:{"backgroud-color":"transparent"},attrs:{"headers":[
        {
          text: '등록된 후기',
          align: 'left',
          sortable: false,
          value: 'answer',
        },
      ],"items":_vm.comments,"items-per-page":5,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"pa-0"},[_vm._v(_vm._s(props.item.answer))])])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }